$futuraNew: 'Futura New Book', sans-serif;
$centurySupra: 'Century Supra T4', sans-serif;
$heliotrope: 'Heliotrope 4', sans-serif;

@import "fonts";
@import "components";
@import "slick";

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  color: #000;
  margin: 0;
  transition: all 0.3s;

  &.open {
    position: fixed;
    height: 100%;
    width: 100%;

    header {
      overflow: initial;
    }
  }
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

p {
  margin: 0;
  padding: 0;
}

.position-relative {
  position: relative;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    padding: 0 15px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.flex-column {
  flex-direction: column;
}

.flex-2 {
  flex: 2;
}

//Margin
.ml-auto {
  margin-left: auto;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mobile-visible {
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
  }
}

header {
  padding: 20px 40px;
  background: #fff;
  border-bottom: 1px solid #000;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  .logo {
    display: flex;
    align-items: center;

    img {
      height: 50px;
      width: auto;
      margin-right: 25px;
      object-fit: contain;
    }

  }

  nav {
    ul {
      li {
        padding: 0 20px;

        a {
          font-family: $futuraNew;
          text-decoration: none;
          text-transform: uppercase;
          color: #000;
          padding-bottom: 3px;
          position: relative;

          &:hover {
            &:after {
              position: absolute;
              content: '';
              width: 100%;
              height: 1px;
              background: #000;
              left: 0;
              bottom: 0;
            }
          }
        }

        &.lang {


          span {
            display: block;
            position: relative;
            padding: 0 10px;

            &:after {
              position: absolute;
              content: '';
              height: 100%;
              width: 1px;
              background: #000;
              left: 10px;
              top: 0;
            }
          }

          a {
            &.active {
              font-weight: bold;
            }
          }
        }
      }
    }

    @media screen and(max-width: 768px) {
      position: absolute;
      top: 91px;
      left: 0;
      width: 100%;
      z-index: 9;
      right: -100%;
      transition: all 0.3s;
      padding: 0 15px 20px;
      height: calc(100vh - 90px);
      background: #fff;
      box-sizing: border-box;

      &.open {
        max-width: 100%;
        transition: all 0.3s;
        right: 0;
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        //padding: 0 35px 20px;
        //height: calc(100% - 90px);
        padding-left: 0;

        background: #fff;
        margin-top: 0;
        box-sizing: border-box;

        li {
          padding: 35px 35px 35px 60px;
          border-bottom: 2px solid #e8e8e9;
          width: 100%;
          box-sizing: border-box;

          &.lang {
            justify-content: center;
            margin-top: auto;
            border-bottom: none;
          }

          a {
            font-size: 16px;
          }

          .sl-col {
            flex: 1;
            display: flex;
            flex-direction: column;
            color: #000;

            span {
              font-family: $futuraNew;
              font-size: 16px;
              margin-bottom: 25px;
              line-height: 1;
              text-transform: uppercase;
            }

            p {
              font-family: $heliotrope;
              font-size: 16px;
              margin-bottom: 15px;
              line-height: 1;
            }
          }
        }
      }
    }

    @media screen and(max-width: 420px) {
      ul {
        padding: 0 15px;

        li {
          padding: 20px;
        }
      }
    }
  }

  .mobile-menu {
    display: none;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    margin-left: auto;
    width: 30px;
    //height: 30px;

    span {
      width: 28px;
      height: 2px;
      background: #000;
      margin-bottom: 6px;
      transition: all 0.3s;
    }

    &.open {
      display: flex;

      span {
        position: absolute;
        transition: all 0.3s;

        &:nth-child(1) {
          transform: rotate(45deg);
          top: -3px;
          left: -1px;
        }

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: -3px;
          left: -1px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      display: flex;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px 30px;
    border-bottom: 1px solid #e8e8e9;
  }
  @media screen and(max-width: 420px) {
    padding: 20px 15px;
  }

}

section {
  margin-bottom: 80px;

  .heading {
    font-family: $centurySupra;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 65px 0;
    padding-bottom: 24px;
    border-bottom: 1px solid #000;
    font-size: 18px;

    @media screen and (max-width: 768px) {
      margin: 0 0 35px 0;
    }
  }
}

.content-wrapper {
  position: relative;
  background: #fff;
  z-index: 999;
}

.content-body {
  //Main banner
  .banner-block {
    display: flex;
    align-items: center;
    justify-content: center;

    .banner-text {
      flex-direction: column;
      align-items: center;
      color: #fff;

      .sup-quote {
        font-family: $futuraNew;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      h1 {
        font-family: $heliotrope;
        margin: 0;
        font-size: 53px;
        text-align: center;
        font-weight: 400;

        @media screen and (max-width: 1200px) {
          font-size: 38px;
        }

        @media screen and (max-width: 550px) {
          font-size: 26px;
        }
      }
    }

    //Main Banner
    &.banner-main {

      @media screen and (min-width: 1921px) {
        background: url('../img/banner-main.png');
        background-attachment: fixed;
        background-position: center bottom 200px;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 1200px;

        .banner-text {
          max-width: 85%;
        }
      }

      background: url('../img/banner-main.png');
      background-attachment: fixed;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 800px;

      .banner-text {
        max-width: 960px;
        width: 100%;
        text-align: center;
      }

      @media screen and (max-width: 768px) {
        background: url('../img/banner-main.png');
        //background: url('../img/banner-main-mobile.png');
        background-attachment: fixed;
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 700px;

        .banner-text {
          max-width: 85%;
        }
      }
      @media screen and (max-width: 420px) {
        height: 550px;
      }

    }

    //Middle banner
    &.banner-middle {
      background: url('../img/banner-middle.png') ;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 550px;

      @media screen and (max-width: 768px) {
        background: url('../img/banner-middle-mobile.png');
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .banner-text {
        max-width: 610px;
        width: 100%;
      }
    }
  }

  //LandMark Cases
  .landmark-cases {

    .cases-list,
    .case-detailed {
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-between;

      &--item {
        width: 24%;
        //max-width: 335px;
        margin-right: 1.3%;
        margin-bottom: 80px;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        &.mr-0 {
          margin-right: 0;
        }

        &.detailed-item {
          border: 1px solid #000;
          padding: 10px;
          max-width: 100%;
          width: 100%;
          margin-bottom: 30px;
          margin-right: 0;

          .detailed-wrapper {
            display: flex;
            width: 100%;

            .img-wrapper {
              flex: 1;
              max-width: 100%;
              margin-right: 20px;
              margin-bottom: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .text {
              width: 300px;
              font-family: $heliotrope;
              font-size: 18px;

              @media screen and (max-width:768px) {
                font-size: 16px;
              }

              .topic {
                font-weight: bold;
              }
            }

            @media screen and (max-width: 768px) {
              flex-direction: column;

              .img-wrapper {
                margin-bottom: 25px;
              }
            }
          }
        }

        @media screen and (max-width: 768px) {
          width: 49%;
          margin-right: 2%;
        }
      }

      .img-wrapper {
        margin-bottom: 25px;

        img {
          width: 100%;
        }
      }

      .date {
        font-family: $centurySupra;
        font-size: 16px;
        margin-bottom: 10px;
        transition: transform .25s ease-in-out 0s;
      }

      .topic {
        font-family: $heliotrope;
        font-size: 20px;
        margin-bottom: 44px;
        line-height: 1;
        transition: transform .25s ease-in-out 0s;
      }

      .read-more {
        margin-top: auto;
        &:after {
          transition: transform .25s ease-in-out 0s;
        }
      }

      //hover
      .cases-list {
        &--item:hover {
          img {
            opacity: 0.9;
          }

          .date,
          .topic,
          .read-more:after {
            transform: translateX(10px);
          }
        }
      }
    }

    .more-cases {
      margin: 0 auto;
      width: 100%;
    }

    .case-detailed {
      //max-width: 1395px;
      width: 100%;

      .detailed-item {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: max-height 0.9s ease-out, opacity 0.9s ease-out;
      }

      &.open {
        .detailed-item {
          opacity: 1;
          transition: max-height 0.9s ease-out, opacity 0.9s ease-out;
          max-height: 2000px  ;
        }
      }
    }
  }

  //International Expertise
  .i-expertise {
    .expertise-list {

      .expertise-list--item {
        display: flex;
        border-bottom: 1px solid #000;
        padding-bottom: 60px;
        margin-bottom: 60px;

        .left {
          flex: 1;

          .img-wrapper {
            width: 100%;
            height: 400px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover
            }

            @media screen and (max-width: 768px) {
              height: 280px;
              margin-bottom: 30px;
            }
          }
        }

        .right {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-left: 55px;

          @media screen and (max-width: 768px) {
            padding-left: 0;
          }

          .topic {
            font-family: $centurySupra;
            font-size: 22px;
            text-transform: uppercase;
            margin-bottom: 30px;
            transition: transform .25s ease-in-out 0s;
          }
        }

        .description {
          font-family: $heliotrope;
          font-size: 24px;
          margin-bottom: 55px;
          transition: transform .25s ease-in-out 0s;
          letter-spacing: 1px;
          line-height: 1.3;

          @media screen and (max-width: 768px) {
            font-size: 22px;
            margin-bottom: 25px;
          }

          &.more-text {
            max-height: 0;
            transition: all 0.3s;
            overflow: hidden;
            margin-bottom: 0;
            letter-spacing: 1px;
            line-height: 1.3;

            &.open {
              display: flex;
            }
          }
        }

        .read-less {
          display: none;
          margin-top: 20px;
          margin-left: auto;

          @media screen and (max-width: 768px) {
            margin-left: 0;
          }
        }

        &.open {
          .description.more-text {
            display: flex;
            max-height: 1000px;
            transition: all 0.3s;
            margin-top: 20px;
          }

          .read-more {
            display: none;
          }

          .read-less {
            display: flex;
          }
        }

        &:last-child {
          padding: 0;
          border-bottom: none;
        }
      }
    }
  }

  //Legal-service
  .legal-service {

    .legal-service--carousel {
      position: initial;

      .carousel-item {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        .img-wrapper {
          margin-bottom: 35px;

          img {
            width: 100%;
          }
        }

        .topic {
          font-family: $heliotrope;
          font-size: 28px;
          margin-bottom: 20px;
        }

        .description {
          font-family: $futuraNew;
          font-size: 20px;
        }

        @media screen and (max-width: 768px) {
          margin-right: 0;
          padding: 0 3px;
        }
      }

      .slick-arrow {
        width: 100px;
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        padding: 5px 0;
        cursor: pointer;

        &.slick-next {
          right: 120px;
          @media screen and (max-width: 768px) {
            right: 10%;
          }
        }

        &.slick-prev {
          right: 240px;
          transform: rotate(180deg);
          @media screen and (max-width: 768px) {
            left: 10%;
            right: auto;
          }
        }

        svg {
          width: 100%;
          fill: gray;
        }

        &:hover {
          svg {
            fill: #000;
            stroke: #000;
          }
        }

        @media screen and (max-width: 768px) {
          top: -30px;
        }
      }
    }

    margin-bottom: 0;
    padding-bottom: 90px;

    @media screen and (max-width: 768px) {
      &.position-relative {
        position: initial;
      }

      .legal-service--carousel {
        position: relative;
      }
    }
  }

  //Legal Notice

  .legal-notice {
    padding-bottom: 50px;

    .container {
      max-width: 1000px;
    }

    h1,
    h2 {
      font-family: $heliotrope;
    }

    p {
      font-family: $futuraNew;
      font-size: 16px;
      margin-bottom: 10px;

      strong {
        font-family: $heliotrope;
      }
    }

    ul {
      list-style: disc;

      li {
        font-family: $futuraNew;
        font-size: 16px;
      }
    }
  }
}

footer {
  background: #000;
  padding-bottom: calc(500px - 2.625rem) !important;

  .footer-inner {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 140px 30px;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 75px 20px;
    }

    .container {
      width: 100%;
    }
  }

  .logo {
    img {
      height: 100%;
      width: 130px;
      object-fit: contain;
    }
  }

  .logo, .sl-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #fff;

    span {
      font-family: $futuraNew;
      font-size: 16px;
      margin-bottom: 25px;
      line-height: 1;
      text-transform: uppercase;
    }

    p {
      font-family: $heliotrope;
      font-size: 18px;
      margin-bottom: 15px;
      line-height: 1;
    }
  }

  .copyright {
    color: #fff;
    text-align: center;
    margin-top: 70px;

    a {
      font-family: $heliotrope;
      color: #fff;
      text-decoration: underline;
    }
  }

  @media screen and(max-width: 768px) {

    padding-bottom: calc(700px - 2.625rem) !important;

    .d-flex {
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    .footer-inner {
      padding-bottom: 30px  ;
      .container {
        padding: 0;
      }
    }
  }
}


@media screen and(max-width: 768px) {
  .mobile-column {
    flex-direction: column;
  }
}