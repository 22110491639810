.sl-btn {
  font-family: $futuraNew;
  max-width: 250px;
  height: 44px;
  padding: 10px;
  border: 1px solid #000;
  color: #000;
  line-height: 1.3;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform .25s ease-in-out 0s;

  &.read-more {
    position: relative;

    &:after {
      content: "\2192";
      font-size: 23px;
      margin-left: 25px;
      color: #000;
      //margin-bottom: 6px;
      transition: transform .25s ease-in-out 0s;
    }

    &:hover {
      color: #ffffff;
      background: #000;

      &:after {
        color: #fff;
        transform: translateX(10px);
      }
    }
  }

  &.btn-black {
    background: #000;
    color: #fff;
    transition: transform .25s ease-in-out 0s;

    &:hover {
      background: #fff;
      color: #000;
    }
  }

  &.read-less {
    position: relative;

    &:before {
      content: "\2190";
      font-size: 23px;
      margin-right: 25px;
      color: #000;
      //margin-bottom: 6px;
      transition: transform .25s ease-in-out 0s;
    }

    &:hover {
      color: #ffffff;
      background: #000;

      &:before {
        color: #fff;
        transform: translateX(-10px);
      }
    }
  }

  &.btn-back {
    position: relative;
    font-family: $heliotrope;
    border: none;
    width: fit-content;

    &:before {
      content: "\2190";
      font-size: 20px;
      margin-right: 10px;
      color: #000;
      margin-bottom: 3px;

    }

    &:hover {
      font-weight: bold;
      &:after {
        font-weight: bold;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    max-width: 220px;
  }
}