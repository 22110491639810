@font-face {
  font-family: 'Futura New Book';
  src: url("../../fonts/FuturaNew/FuturaNewBook-Reg.woff2") format("woff2"), url("../../fonts/FuturaNew/FuturaNewBook-Reg.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Heliotrope 4';
  src: url("../../fonts/Heliotrope4/Heliotrope4Italic.woff2") format("woff2"), url("../../fonts/Heliotrope4/Heliotrope4Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Heliotrope 4';
  src: url("../../fonts/Heliotrope4/Heliotrope4Bold.woff2") format("woff2"), url("../../fonts/Heliotrope4/Heliotrope4Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Heliotrope 4';
  src: url("../../fonts/Heliotrope4/Heliotrope4BoldItalic.woff2") format("woff2"), url("../../fonts/Heliotrope4/Heliotrope4BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Heliotrope 4';
  src: url("../../fonts/Heliotrope4/Heliotrope4Regular.woff2") format("woff2"), url("../../fonts/Heliotrope4/Heliotrope4Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Century Supra T4';
  src: url("../../fonts/CenturySupraT4/CenturySupraT4-Bold.woff2") format("woff2"), url("../../fonts/CenturySupraT4/CenturySupraT4-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Century Supra T4';
  src: url("../../fonts/CenturySupraT4/CenturySupraT4-BoldItalic.woff2") format("woff2"), url("../../fonts/CenturySupraT4/CenturySupraT4-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Century Supra T4';
  src: url("../../fonts/CenturySupraT4/CenturySupraT4-Italic.woff2") format("woff2"), url("../../fonts/CenturySupraT4/CenturySupraT4-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Century Supra T4';
  src: url("../../fonts/CenturySupraT4/CenturySupraT4.woff2") format("woff2"), url("../../fonts/CenturySupraT4/CenturySupraT4.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

.sl-btn {
  font-family: "Futura New Book", sans-serif;
  max-width: 250px;
  height: 44px;
  padding: 10px;
  border: 1px solid #000;
  color: #000;
  line-height: 1.3;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform .25s ease-in-out 0s; }
  .sl-btn.read-more {
    position: relative; }
    .sl-btn.read-more:after {
      content: "\2192";
      font-size: 23px;
      margin-left: 25px;
      color: #000;
      transition: transform .25s ease-in-out 0s; }
    .sl-btn.read-more:hover {
      color: #ffffff;
      background: #000; }
      .sl-btn.read-more:hover:after {
        color: #fff;
        transform: translateX(10px); }
  .sl-btn.btn-black {
    background: #000;
    color: #fff;
    transition: transform .25s ease-in-out 0s; }
    .sl-btn.btn-black:hover {
      background: #fff;
      color: #000; }
  .sl-btn.read-less {
    position: relative; }
    .sl-btn.read-less:before {
      content: "\2190";
      font-size: 23px;
      margin-right: 25px;
      color: #000;
      transition: transform .25s ease-in-out 0s; }
    .sl-btn.read-less:hover {
      color: #ffffff;
      background: #000; }
      .sl-btn.read-less:hover:before {
        color: #fff;
        transform: translateX(-10px); }
  .sl-btn.btn-back {
    position: relative;
    font-family: "Heliotrope 4", sans-serif;
    border: none;
    width: fit-content; }
    .sl-btn.btn-back:before {
      content: "\2190";
      font-size: 20px;
      margin-right: 10px;
      color: #000;
      margin-bottom: 3px; }
    .sl-btn.btn-back:hover {
      font-weight: bold; }
      .sl-btn.btn-back:hover:after {
        font-weight: bold; }
  @media screen and (max-width: 1200px) {
    .sl-btn {
      max-width: 220px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

html {
  scroll-behavior: smooth; }

body {
  height: 100%;
  color: #000;
  margin: 0;
  transition: all 0.3s; }
  body.open {
    position: fixed;
    height: 100%;
    width: 100%; }
    body.open header {
      overflow: initial; }

a {
  text-decoration: none; }

ul {
  list-style: none; }

p {
  margin: 0;
  padding: 0; }

.position-relative {
  position: relative; }

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box; }
  @media screen and (max-width: 1200px) {
    .container {
      padding: 0 15px; } }
  @media screen and (max-width: 768px) {
    .container {
      padding: 0 15px; } }

.d-flex {
  display: flex; }

.align-center {
  align-items: center; }

.justify-center {
  justify-content: center; }

.justify-content-start {
  justify-content: flex-start; }

.align-items-center {
  align-items: center; }

.align-items-start {
  align-items: flex-start; }

.flex-column {
  flex-direction: column; }

.flex-2 {
  flex: 2; }

.ml-auto {
  margin-left: auto; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mobile-visible {
  display: none; }
  @media screen and (max-width: 768px) {
    .mobile-visible {
      display: flex; } }

header {
  padding: 20px 40px;
  background: #fff;
  border-bottom: 1px solid #000;
  position: relative;
  box-sizing: border-box;
  overflow: hidden; }
  header .logo {
    display: flex;
    align-items: center; }
    header .logo img {
      height: 50px;
      width: auto;
      margin-right: 25px;
      object-fit: contain; }
  header nav ul li {
    padding: 0 20px; }
    header nav ul li a {
      font-family: "Futura New Book", sans-serif;
      text-decoration: none;
      text-transform: uppercase;
      color: #000;
      padding-bottom: 3px;
      position: relative; }
      header nav ul li a:hover:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background: #000;
        left: 0;
        bottom: 0; }
    header nav ul li.lang span {
      display: block;
      position: relative;
      padding: 0 10px; }
      header nav ul li.lang span:after {
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        background: #000;
        left: 10px;
        top: 0; }
    header nav ul li.lang a.active {
      font-weight: bold; }
  @media screen and (max-width: 768px) {
    header nav {
      position: absolute;
      top: 91px;
      left: 0;
      width: 100%;
      z-index: 9;
      right: -100%;
      transition: all 0.3s;
      padding: 0 15px 20px;
      height: calc(100vh - 90px);
      background: #fff;
      box-sizing: border-box; }
      header nav.open {
        max-width: 100%;
        transition: all 0.3s;
        right: 0; }
      header nav ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        padding-left: 0;
        background: #fff;
        margin-top: 0;
        box-sizing: border-box; }
        header nav ul li {
          padding: 35px 35px 35px 60px;
          border-bottom: 2px solid #e8e8e9;
          width: 100%;
          box-sizing: border-box; }
          header nav ul li.lang {
            justify-content: center;
            margin-top: auto;
            border-bottom: none; }
          header nav ul li a {
            font-size: 16px; }
          header nav ul li .sl-col {
            flex: 1;
            display: flex;
            flex-direction: column;
            color: #000; }
            header nav ul li .sl-col span {
              font-family: "Futura New Book", sans-serif;
              font-size: 16px;
              margin-bottom: 25px;
              line-height: 1;
              text-transform: uppercase; }
            header nav ul li .sl-col p {
              font-family: "Heliotrope 4", sans-serif;
              font-size: 16px;
              margin-bottom: 15px;
              line-height: 1; } }
  @media screen and (max-width: 420px) {
    header nav ul {
      padding: 0 15px; }
      header nav ul li {
        padding: 20px; } }
  header .mobile-menu {
    display: none;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    margin-left: auto;
    width: 30px; }
    header .mobile-menu span {
      width: 28px;
      height: 2px;
      background: #000;
      margin-bottom: 6px;
      transition: all 0.3s; }
    header .mobile-menu.open {
      display: flex; }
      header .mobile-menu.open span {
        position: absolute;
        transition: all 0.3s; }
        header .mobile-menu.open span:nth-child(1) {
          transform: rotate(45deg);
          top: -3px;
          left: -1px; }
        header .mobile-menu.open span:nth-child(2) {
          display: none; }
        header .mobile-menu.open span:nth-child(3) {
          transform: rotate(-45deg);
          top: -3px;
          left: -1px; }
    @media screen and (max-width: 768px) {
      header .mobile-menu {
        display: flex; } }
  @media screen and (max-width: 768px) {
    header {
      padding: 20px 30px;
      border-bottom: 1px solid #e8e8e9; } }
  @media screen and (max-width: 420px) {
    header {
      padding: 20px 15px; } }

section {
  margin-bottom: 80px; }
  section .heading {
    font-family: "Century Supra T4", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 65px 0;
    padding-bottom: 24px;
    border-bottom: 1px solid #000;
    font-size: 18px; }
    @media screen and (max-width: 768px) {
      section .heading {
        margin: 0 0 35px 0; } }

.content-wrapper {
  position: relative;
  background: #fff;
  z-index: 999; }

.content-body .banner-block {
  display: flex;
  align-items: center;
  justify-content: center; }
  .content-body .banner-block .banner-text {
    flex-direction: column;
    align-items: center;
    color: #fff; }
    .content-body .banner-block .banner-text .sup-quote {
      font-family: "Futura New Book", sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 30px; }
    .content-body .banner-block .banner-text h1 {
      font-family: "Heliotrope 4", sans-serif;
      margin: 0;
      font-size: 53px;
      text-align: center;
      font-weight: 400; }
      @media screen and (max-width: 1200px) {
        .content-body .banner-block .banner-text h1 {
          font-size: 38px; } }
      @media screen and (max-width: 550px) {
        .content-body .banner-block .banner-text h1 {
          font-size: 26px; } }
  .content-body .banner-block.banner-main {
    background: url("../img/banner-main.png");
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 800px; }
    @media screen and (min-width: 1921px) {
      .content-body .banner-block.banner-main {
        background: url("../img/banner-main.png");
        background-attachment: fixed;
        background-position: center bottom 200px;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 1200px; }
        .content-body .banner-block.banner-main .banner-text {
          max-width: 85%; } }
    .content-body .banner-block.banner-main .banner-text {
      max-width: 960px;
      width: 100%;
      text-align: center; }
    @media screen and (max-width: 768px) {
      .content-body .banner-block.banner-main {
        background: url("../img/banner-main.png");
        background-attachment: fixed;
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 700px; }
        .content-body .banner-block.banner-main .banner-text {
          max-width: 85%; } }
    @media screen and (max-width: 420px) {
      .content-body .banner-block.banner-main {
        height: 550px; } }
  .content-body .banner-block.banner-middle {
    background: url("../img/banner-middle.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 550px; }
    @media screen and (max-width: 768px) {
      .content-body .banner-block.banner-middle {
        background: url("../img/banner-middle-mobile.png");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; } }
    .content-body .banner-block.banner-middle .banner-text {
      max-width: 610px;
      width: 100%; }

.content-body .landmark-cases .cases-list,
.content-body .landmark-cases .case-detailed {
  display: flex;
  flex-wrap: wrap; }
  .content-body .landmark-cases .cases-list--item,
  .content-body .landmark-cases .case-detailed--item {
    width: 24%;
    margin-right: 1.3%;
    margin-bottom: 80px;
    cursor: pointer;
    display: flex;
    flex-direction: column; }
    .content-body .landmark-cases .cases-list--item.mr-0,
    .content-body .landmark-cases .case-detailed--item.mr-0 {
      margin-right: 0; }
    .content-body .landmark-cases .cases-list--item.detailed-item,
    .content-body .landmark-cases .case-detailed--item.detailed-item {
      border: 1px solid #000;
      padding: 10px;
      max-width: 100%;
      width: 100%;
      margin-bottom: 30px;
      margin-right: 0; }
      .content-body .landmark-cases .cases-list--item.detailed-item .detailed-wrapper,
      .content-body .landmark-cases .case-detailed--item.detailed-item .detailed-wrapper {
        display: flex;
        width: 100%; }
        .content-body .landmark-cases .cases-list--item.detailed-item .detailed-wrapper .img-wrapper,
        .content-body .landmark-cases .case-detailed--item.detailed-item .detailed-wrapper .img-wrapper {
          flex: 1;
          max-width: 100%;
          margin-right: 20px;
          margin-bottom: 0; }
          .content-body .landmark-cases .cases-list--item.detailed-item .detailed-wrapper .img-wrapper img,
          .content-body .landmark-cases .case-detailed--item.detailed-item .detailed-wrapper .img-wrapper img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
        .content-body .landmark-cases .cases-list--item.detailed-item .detailed-wrapper .text,
        .content-body .landmark-cases .case-detailed--item.detailed-item .detailed-wrapper .text {
          width: 300px;
          font-family: "Heliotrope 4", sans-serif;
          font-size: 18px; }
          @media screen and (max-width: 768px) {
            .content-body .landmark-cases .cases-list--item.detailed-item .detailed-wrapper .text,
            .content-body .landmark-cases .case-detailed--item.detailed-item .detailed-wrapper .text {
              font-size: 16px; } }
          .content-body .landmark-cases .cases-list--item.detailed-item .detailed-wrapper .text .topic,
          .content-body .landmark-cases .case-detailed--item.detailed-item .detailed-wrapper .text .topic {
            font-weight: bold; }
        @media screen and (max-width: 768px) {
          .content-body .landmark-cases .cases-list--item.detailed-item .detailed-wrapper,
          .content-body .landmark-cases .case-detailed--item.detailed-item .detailed-wrapper {
            flex-direction: column; }
            .content-body .landmark-cases .cases-list--item.detailed-item .detailed-wrapper .img-wrapper,
            .content-body .landmark-cases .case-detailed--item.detailed-item .detailed-wrapper .img-wrapper {
              margin-bottom: 25px; } }
    @media screen and (max-width: 768px) {
      .content-body .landmark-cases .cases-list--item,
      .content-body .landmark-cases .case-detailed--item {
        width: 49%;
        margin-right: 2%; } }
  .content-body .landmark-cases .cases-list .img-wrapper,
  .content-body .landmark-cases .case-detailed .img-wrapper {
    margin-bottom: 25px; }
    .content-body .landmark-cases .cases-list .img-wrapper img,
    .content-body .landmark-cases .case-detailed .img-wrapper img {
      width: 100%; }
  .content-body .landmark-cases .cases-list .date,
  .content-body .landmark-cases .case-detailed .date {
    font-family: "Century Supra T4", sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    transition: transform .25s ease-in-out 0s; }
  .content-body .landmark-cases .cases-list .topic,
  .content-body .landmark-cases .case-detailed .topic {
    font-family: "Heliotrope 4", sans-serif;
    font-size: 20px;
    margin-bottom: 44px;
    line-height: 1;
    transition: transform .25s ease-in-out 0s; }
  .content-body .landmark-cases .cases-list .read-more,
  .content-body .landmark-cases .case-detailed .read-more {
    margin-top: auto; }
    .content-body .landmark-cases .cases-list .read-more:after,
    .content-body .landmark-cases .case-detailed .read-more:after {
      transition: transform .25s ease-in-out 0s; }
  .content-body .landmark-cases .cases-list .cases-list--item:hover img,
  .content-body .landmark-cases .case-detailed .cases-list--item:hover img {
    opacity: 0.9; }
  .content-body .landmark-cases .cases-list .cases-list--item:hover .date,
  .content-body .landmark-cases .cases-list .cases-list--item:hover .topic,
  .content-body .landmark-cases .cases-list .cases-list--item:hover .read-more:after,
  .content-body .landmark-cases .case-detailed .cases-list--item:hover .date,
  .content-body .landmark-cases .case-detailed .cases-list--item:hover .topic,
  .content-body .landmark-cases .case-detailed .cases-list--item:hover .read-more:after {
    transform: translateX(10px); }

.content-body .landmark-cases .more-cases {
  margin: 0 auto;
  width: 100%; }

.content-body .landmark-cases .case-detailed {
  width: 100%; }
  .content-body .landmark-cases .case-detailed .detailed-item {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.9s ease-out, opacity 0.9s ease-out; }
  .content-body .landmark-cases .case-detailed.open .detailed-item {
    opacity: 1;
    transition: max-height 0.9s ease-out, opacity 0.9s ease-out;
    max-height: 2000px; }

.content-body .i-expertise .expertise-list .expertise-list--item {
  display: flex;
  border-bottom: 1px solid #000;
  padding-bottom: 60px;
  margin-bottom: 60px; }
  .content-body .i-expertise .expertise-list .expertise-list--item .left {
    flex: 1; }
    .content-body .i-expertise .expertise-list .expertise-list--item .left .img-wrapper {
      width: 100%;
      height: 400px; }
      .content-body .i-expertise .expertise-list .expertise-list--item .left .img-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      @media screen and (max-width: 768px) {
        .content-body .i-expertise .expertise-list .expertise-list--item .left .img-wrapper {
          height: 280px;
          margin-bottom: 30px; } }
  .content-body .i-expertise .expertise-list .expertise-list--item .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 55px; }
    @media screen and (max-width: 768px) {
      .content-body .i-expertise .expertise-list .expertise-list--item .right {
        padding-left: 0; } }
    .content-body .i-expertise .expertise-list .expertise-list--item .right .topic {
      font-family: "Century Supra T4", sans-serif;
      font-size: 22px;
      text-transform: uppercase;
      margin-bottom: 30px;
      transition: transform .25s ease-in-out 0s; }
  .content-body .i-expertise .expertise-list .expertise-list--item .description {
    font-family: "Heliotrope 4", sans-serif;
    font-size: 24px;
    margin-bottom: 55px;
    transition: transform .25s ease-in-out 0s;
    letter-spacing: 1px;
    line-height: 1.3; }
    @media screen and (max-width: 768px) {
      .content-body .i-expertise .expertise-list .expertise-list--item .description {
        font-size: 22px;
        margin-bottom: 25px; } }
    .content-body .i-expertise .expertise-list .expertise-list--item .description.more-text {
      max-height: 0;
      transition: all 0.3s;
      overflow: hidden;
      margin-bottom: 0;
      letter-spacing: 1px;
      line-height: 1.3; }
      .content-body .i-expertise .expertise-list .expertise-list--item .description.more-text.open {
        display: flex; }
  .content-body .i-expertise .expertise-list .expertise-list--item .read-less {
    display: none;
    margin-top: 20px;
    margin-left: auto; }
    @media screen and (max-width: 768px) {
      .content-body .i-expertise .expertise-list .expertise-list--item .read-less {
        margin-left: 0; } }
  .content-body .i-expertise .expertise-list .expertise-list--item.open .description.more-text {
    display: flex;
    max-height: 1000px;
    transition: all 0.3s;
    margin-top: 20px; }
  .content-body .i-expertise .expertise-list .expertise-list--item.open .read-more {
    display: none; }
  .content-body .i-expertise .expertise-list .expertise-list--item.open .read-less {
    display: flex; }
  .content-body .i-expertise .expertise-list .expertise-list--item:last-child {
    padding: 0;
    border-bottom: none; }

.content-body .legal-service {
  margin-bottom: 0;
  padding-bottom: 90px; }
  .content-body .legal-service .legal-service--carousel {
    position: initial; }
    .content-body .legal-service .legal-service--carousel .carousel-item {
      display: flex;
      flex-direction: column;
      margin-right: 20px; }
      .content-body .legal-service .legal-service--carousel .carousel-item .img-wrapper {
        margin-bottom: 35px; }
        .content-body .legal-service .legal-service--carousel .carousel-item .img-wrapper img {
          width: 100%; }
      .content-body .legal-service .legal-service--carousel .carousel-item .topic {
        font-family: "Heliotrope 4", sans-serif;
        font-size: 28px;
        margin-bottom: 20px; }
      .content-body .legal-service .legal-service--carousel .carousel-item .description {
        font-family: "Futura New Book", sans-serif;
        font-size: 20px; }
      @media screen and (max-width: 768px) {
        .content-body .legal-service .legal-service--carousel .carousel-item {
          margin-right: 0;
          padding: 0 3px; } }
    .content-body .legal-service .legal-service--carousel .slick-arrow {
      width: 100px;
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      padding: 5px 0;
      cursor: pointer; }
      .content-body .legal-service .legal-service--carousel .slick-arrow.slick-next {
        right: 120px; }
        @media screen and (max-width: 768px) {
          .content-body .legal-service .legal-service--carousel .slick-arrow.slick-next {
            right: 10%; } }
      .content-body .legal-service .legal-service--carousel .slick-arrow.slick-prev {
        right: 240px;
        transform: rotate(180deg); }
        @media screen and (max-width: 768px) {
          .content-body .legal-service .legal-service--carousel .slick-arrow.slick-prev {
            left: 10%;
            right: auto; } }
      .content-body .legal-service .legal-service--carousel .slick-arrow svg {
        width: 100%;
        fill: gray; }
      .content-body .legal-service .legal-service--carousel .slick-arrow:hover svg {
        fill: #000;
        stroke: #000; }
      @media screen and (max-width: 768px) {
        .content-body .legal-service .legal-service--carousel .slick-arrow {
          top: -30px; } }
  @media screen and (max-width: 768px) {
    .content-body .legal-service.position-relative {
      position: initial; }
    .content-body .legal-service .legal-service--carousel {
      position: relative; } }

.content-body .legal-notice {
  padding-bottom: 50px; }
  .content-body .legal-notice .container {
    max-width: 1000px; }
  .content-body .legal-notice h1,
  .content-body .legal-notice h2 {
    font-family: "Heliotrope 4", sans-serif; }
  .content-body .legal-notice p {
    font-family: "Futura New Book", sans-serif;
    font-size: 16px;
    margin-bottom: 10px; }
    .content-body .legal-notice p strong {
      font-family: "Heliotrope 4", sans-serif; }
  .content-body .legal-notice ul {
    list-style: disc; }
    .content-body .legal-notice ul li {
      font-family: "Futura New Book", sans-serif;
      font-size: 16px; }

footer {
  background: #000;
  padding-bottom: calc(500px - 2.625rem) !important; }
  footer .footer-inner {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 140px 30px;
    box-sizing: border-box; }
    @media screen and (max-width: 768px) {
      footer .footer-inner {
        padding: 75px 20px; } }
    footer .footer-inner .container {
      width: 100%; }
  footer .logo img {
    height: 100%;
    width: 130px;
    object-fit: contain; }
  footer .logo, footer .sl-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #fff; }
    footer .logo span, footer .sl-col span {
      font-family: "Futura New Book", sans-serif;
      font-size: 16px;
      margin-bottom: 25px;
      line-height: 1;
      text-transform: uppercase; }
    footer .logo p, footer .sl-col p {
      font-family: "Heliotrope 4", sans-serif;
      font-size: 18px;
      margin-bottom: 15px;
      line-height: 1; }
  footer .copyright {
    color: #fff;
    text-align: center;
    margin-top: 70px; }
    footer .copyright a {
      font-family: "Heliotrope 4", sans-serif;
      color: #fff;
      text-decoration: underline; }
  @media screen and (max-width: 768px) {
    footer {
      padding-bottom: calc(700px - 2.625rem) !important; }
      footer .d-flex {
        flex-direction: column;
        text-align: center;
        align-items: center; }
      footer .footer-inner {
        padding-bottom: 30px; }
        footer .footer-inner .container {
          padding: 0; } }

@media screen and (max-width: 768px) {
  .mobile-column {
    flex-direction: column; } }
