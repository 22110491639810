@font-face {
  font-family: 'Futura New Book';
  src: url('../../fonts/FuturaNew/FuturaNewBook-Reg.woff2') format('woff2'),
  url('../../fonts/FuturaNew/FuturaNewBook-Reg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heliotrope 4';
  src: url('../../fonts/Heliotrope4/Heliotrope4Italic.woff2') format('woff2'),
  url('../../fonts/Heliotrope4/Heliotrope4Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Heliotrope 4';
  src: url('../../fonts/Heliotrope4/Heliotrope4Bold.woff2') format('woff2'),
  url('../../fonts/Heliotrope4/Heliotrope4Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heliotrope 4';
  src: url('../../fonts/Heliotrope4/Heliotrope4BoldItalic.woff2') format('woff2'),
  url('../../fonts/Heliotrope4/Heliotrope4BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Heliotrope 4';
  src: url('../../fonts/Heliotrope4/Heliotrope4Regular.woff2') format('woff2'),
  url('../../fonts/Heliotrope4/Heliotrope4Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Century Supra T4';
  src: url('../../fonts/CenturySupraT4/CenturySupraT4-Bold.woff2') format('woff2'),
  url('../../fonts/CenturySupraT4/CenturySupraT4-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Century Supra T4';
  src: url('../../fonts/CenturySupraT4/CenturySupraT4-BoldItalic.woff2') format('woff2'),
  url('../../fonts/CenturySupraT4/CenturySupraT4-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Century Supra T4';
  src: url('../../fonts/CenturySupraT4/CenturySupraT4-Italic.woff2') format('woff2'),
  url('../../fonts/CenturySupraT4/CenturySupraT4-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Century Supra T4';
  src: url('../../fonts/CenturySupraT4/CenturySupraT4.woff2') format('woff2'),
  url('../../fonts/CenturySupraT4/CenturySupraT4.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

